import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable max-len */

/**
 * Menu
 *
 * @param {object} props
 * @param {string} props.color
 * @param {number} props.size
 * @returns {React.ReactElement}
 */

const IconMenu = ({
  color,
  size,
}: {
  color: string;
  size: number;
}): JSX.Element => (
  <svg height={size} viewBox="0 0 16 16" width={size}>
    <path
      d="M0 3C0 2.44772 0.447715 2 1 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H1C0.447715 4 0 3.55228 0 3ZM0 13C0 12.4477 0.447715 12 1 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H1C0.447715 14 0 13.5523 0 13ZM1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H1Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

IconMenu.defaultProps = {
  color: null,
  size: 16,
};

IconMenu.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default IconMenu;
