import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import GlobalStyle from '../../../styles/GlobalStyle';
import AppFooter from './Footer';
import AppHeader from './Header';
import AppMain from './Main';

const Index: FC<{ isLogged?: boolean }> = ({ isLogged, children }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <html lang="fr" />
        <title>{t('site/sitemetadata/title')}</title>
      </Helmet>
      <GlobalStyle />
      <div className="flex flex-col min-h-screen">
        <AppHeader isLogged={isLogged} />
        <AppMain>{children}</AppMain>
        <AppFooter />
      </div>
    </>
  );
};

export default Index;
