import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1,150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90,150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90,150;
    stroke-dashoffset: -124;
  }
`;

const Svg = styled.svg`
  animation: ${rotate} 2s linear infinite;
`;

const Circle = styled.circle`
  stroke-dasharray: 1, 150;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: ${dash} 1.5s ease-in-out infinite;
`;

/* eslint-disable max-len */

/**
 * Loading
 *
 * @param {object} props
 * @param {string} props.color
 * @param {number} props.size
 * @returns {React.ReactNode}
 */

const IconLoading = ({
  color,
  size,
}: {
  color: string;
  size: number;
}): JSX.Element => (
  <Svg className="no-print" height={size} viewBox="0 0 52 52" width={size}>
    <Circle
      className="path"
      cx="26px"
      cy="26px"
      fill="none"
      r="20px"
      stroke={color || 'currentColor'}
      strokeWidth="4px"
    />
  </Svg>
);

IconLoading.defaultProps = {
  color: null,
  size: 16,
};

IconLoading.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default IconLoading;
