import styled, { ThemeProps } from 'styled-components';

import { colors, DefaultThemeProps } from '../../../../styles/theme';

const Wrapper = styled.div<ThemeProps<DefaultThemeProps>>`
  flex-shrink: 0;
  background: ${colors.light[50]};
  border-top: 1px solid ${(props): string => props.theme.colors.light[600]};
  a {
    text-decoration: none;
  }
`;

export default Wrapper;
