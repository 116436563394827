import { rem, rgba } from 'polished';
import React, { FC } from 'react';
import styled, { ThemeProps } from 'styled-components';

import {
  colors,
  DefaultThemeProps,
  radius,
  spacing,
  transition,
} from '../../../styles/theme';
import Link from '../Link';

interface LinkProps extends ThemeProps<DefaultThemeProps> {
  activeClassName: string;
  emphasize?: boolean;
}

const NavLinkEl = styled(Link)<LinkProps>`
  display: inline-flex;
  align-items: center;
  line-height: ${rem(40)};
  height: ${rem(40)};
  text-decoration: none;
  font-weight: ${(props): string => props.theme.font.weight.medium};
  font-size: ${(props): string => rem(props.theme.font.size.s)};
  color: ${colors.light[100]};
  background: ${(props): string =>
    props.emphasize ? colors.primary[500] : 'transparent'};
  transition: background-color ${transition.timing.base}
    ${transition.easing.base};
  border-radius: ${radius.s};
  background: rgba(${colors.light[50]}, 0.1);

  &:hover,
  &:focus {
    color: ${colors.light[500]};
    background: ${rgba(colors.light[50], 0.1)};
  }
  > * + * {
    margin-left: ${spacing.xs};
  }
  &.is-active {
    background: ${rgba(colors.light[50], 0.1)};
    color: ${colors.light[500]};
  }
  svg {
    flex-shrink: 0;
  }
`;

const NavLink: FC<{
  className?: string;
  emphasize?: boolean;
  partiallyActive?: boolean;
  to: string;
}> = ({ children, className, emphasize = false, partiallyActive, to }) => (
  <NavLinkEl
    activeClassName="is-active"
    className={`${className} py-0 px-3`}
    emphasize={emphasize}
    partiallyActive={partiallyActive}
    to={to}
  >
    {children}
  </NavLinkEl>
);

export default NavLink;
