import React from 'react';

import ContainerEl from './styles';

const Container = ({
  children,
  className,
  px = 'px-2',
  size,
}: {
  children: React.ReactNode;
  className?: string;
  px?: string;
  size?: 's' | 'm' | 'l';
}): JSX.Element => (
  <ContainerEl
    className={`mx-auto ${px} md:px-4 xl:px-6 ${className}`}
    size={size}
  >
    {children}
  </ContainerEl>
);

export default Container;
