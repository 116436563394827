import { css } from 'styled-components';

import { colors } from './theme';

const print = css`
  @media print {
    * {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      background: none !important;
      text-shadow: none !important;
    }
    body {
      width: auto;
      margin: auto;
      font-size: 10pt;
      background: transparent;
      color: ${colors.print.base};
    }
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    blockquote,
    label,
    ul,
    ol {
      color: ${colors.print.base};
      margin: auto;
    }
    .print {
      display: block !important;
    }
    .print-inline {
      display: inline !important;
    }
    .no-print {
      display: none !important;
    }
    .print-break-page {
      page-break-before: always;
    }
    /* no orphans, no widows */
    p,
    blockquote {
      orphans: 3;
      widows: 3;
    }
    /* no breaks inside these elements */
    blockquote,
    ul,
    ol {
      page-break-inside: avoid;
    }
    /* page break before main headers */
    h1,
    h2,
    h3,
    caption {
      page-break-after: avoid;
    }
    a {
      color: #000;
      a[href^='javascript:']::after,
      a[href^='#']::after {
        content: '';
      }

      .print-in-black {
        color: #000 !important;
      }
    }
  }
`;

export default print;
