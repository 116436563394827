import { darken, rem } from 'polished';
import styled, { ThemeProps } from 'styled-components';

import {
  boxShadow,
  breakpoint,
  DefaultThemeProps,
  header,
  mediaQueryMax,
  mediaQueryMin,
} from '../../../../styles/theme';
import Container from '../../../base/Container';

export const AppHeaderElContainer = styled(Container)``;

export const AppHeaderElContainerInner = styled.div<
  ThemeProps<DefaultThemeProps>
>`
  height: ${rem(header.height.small)};
  ${mediaQueryMin(breakpoint.tablet)} {
    height: ${rem(header.height.base)};
  }
  align-items: center;

  .is-open {
    ${mediaQueryMax(breakpoint.tablet)} {
      display: block;
      position: absolute;
      top: ${rem(header.height.small)};
      left: 0;
      right: 0;
      background: ${(props: ThemeProps<DefaultThemeProps>): string =>
        darken(0.05, props.theme.colors.primary[600])};
      box-shadow: ${boxShadow.big};
      ${mediaQueryMin(breakpoint.tablet)} {
        top: ${rem(header.height.base)};
      }
    }
  }
`;
