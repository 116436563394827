import { rem } from 'polished';
import styled, { css, FlattenInterpolation } from 'styled-components';

type Props = {
  size?: string;
};

const ContainerElSizeStyles = (props: Props): FlattenInterpolation<any> => {
  switch (props.size) {
    case 's':
      return css`
        max-width: ${rem(760)};
      `;
    case 'm':
      return css`
        max-width: ${rem(1000)};
      `;
    case 'l':
      return css`
        max-width: ${rem(1400)};
      `;
    default:
      return css`
        max-width: initial;
      `;
  }
};

const ContainerEl = styled.div<Props>`
  ${(props: Props): FlattenInterpolation<any> => ContainerElSizeStyles(props)};
  @media print {
    padding-left: 5mm !important;
    padding-right: 5mm !important;
    max-width: initial !important;
  }
`;

export default ContainerEl;
