import React from 'react';
import { useTranslation } from 'react-i18next';

import Container from '../../../base/Container';
import Wrapper from './styles';

const AppFooter = (): JSX.Element => {
  const { t } = useTranslation();
  const tmp = process.env.GATSBY_ENVIRONMENT
    ? process.env.GATSBY_ENVIRONMENT.split('/')
    : ['develop'];
  const env = tmp[tmp.length - 1];

  return (
    <Wrapper className="py-8 bg-white">
      <Container size="l">
        <div className="grid grid-col-1 lg:grid-cols-2 gap-4">
          <div className="text-center lg:text-left">
            ©&nbsp;
            {t('site/sitemetadata/footer/copyright')}
            <p>
              <small>{t('site/sitemetadata/footer/address')}</small>
            </p>
          </div>
          <div className="text-center lg:text-right">
            <span>{`${env} - v${process.env.GATSBY_VERSION}`}</span>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default AppFooter;
