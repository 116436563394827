import '../i18n';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * translate component
 *
 * @param {object} props
 * @returns {*}
 */
const Translate = ({
  children,
  i18nKey,
  ns,
  prefix,
  suffix,
}: {
  children: string;
  i18nKey: string;
  ns: string;
  prefix: string;
  suffix: string;
}): JSX.Element => {
  const { t } = useTranslation(ns);
  if (!i18nKey && !children) {
    throw new Error(`Translate : the parameters are invalid.`);
  }
  if (i18nKey && t(i18nKey)) {
    return t(i18nKey);
  }

  if (prefix || suffix) {
    if (prefix && suffix) {
      if (t(`${prefix}/${children}/${suffix}`, { saveMissing: false })) {
        return t(`${prefix}/${children}/${suffix}`);
      }
    } else if (prefix) {
      if (t(`${prefix}/${children}`)) {
        return t(`${prefix}/${children}`);
      }
    } else if (t(`${children}/${suffix}`)) {
      return t(`${children}/${suffix}`);
    }
  }

  return t(children);
};

Translate.defaultProps = {
  children: null,
  i18nKey: null,
  ns: null,
  prefix: null,
  suffix: null,
};

Translate.propTypes = {
  children: PropTypes.node,
  i18nKey: PropTypes.string,
  ns: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

export default Translate;
