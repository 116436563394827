import { rem } from 'polished';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import print from './print';
import {
  breakpoint,
  colors,
  font,
  lineHeight,
  mediaQueryMax,
  transition,
} from './theme';

export default createGlobalStyle`
  ${normalize};
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  html, body, #___gatsby {
    height: 100%;
  }
  
  body {
    font-family: ${font.family.base};
    font-size: ${rem(font.size.s)};
    background: ${colors.light[500]};
    color: ${colors.dark[500]};
    overflow-y: scroll;
    overflow-x: hidden;
    line-height: ${lineHeight.l};
    text-rendering: optimizeLegibility;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1 {
    font-size: ${rem(font.size.xxl)};
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
    font-weight: bold;
  }

  h2 {
    font-weight: bold;
    font-size: ${rem(font.size.xl)};
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
  }

  h3 {
    font-weight: bold;
    font-size: ${rem(font.size.l)};
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
  }

  h4 {
    font-weight: bold;
    font-size: ${rem(font.size.m)};
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
  }



.list-disc .attributs ul {
  list-style-type: none;
  margin: 0 2rem;
}

.list-disc  details, .list-disc table {
margin: 0 auto;
margin-bottom: 1rem;
 }
 
.list-disc details {
  margin-left: 3rem;
  padding: 1rem;
}

 .list-disc details summary {
cursor: pointer; 
 }

.list-disc summary {
  font-size:  ${rem(font.size.xl)}
}

.list-disc td, .list-disc th{
  border: 1px solid #ddd;
  padding: 8px;
}

  .gatsby-highlight {
    border-radius: 0.3em;
    margin: 0.5em 0;
    padding: 1em;
        margin-left: 2rem;
        overflow: auto;
  }
  
  .gatsby-highlight pre[class*="language-"] {
    background-color: transparent;
    margin: 0;
    padding: 0;
    overflow: initial;
    float: left; /* 1 */
    min-width: 100%; /* 2 */
  }

    .gatsby-highlight pre[class*="language-"].line-numbers {
      padding-left: 2.8em;
   }

  /* Max values */
  img,
  table,
  td,
  blockquote,
  textarea,
  input,
  video,
  svg {
    max-width: 100%;
  }
  img {
    height: auto;
  }
  
  /* Typography */
  small {
    font-size: 80%;
  }
  abbr[title] {
    text-transform: lowercase;
  }
  abbr,
  acronym {
    cursor: help;
  }
  blockquote {
    margin: 0;
  }

  p,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    word-break: break-word;
    hyphens: auto;
  }
  
   /* Links */
  a {
    transition: color ${transition.timing.base} ${transition.easing.base};
    &:active,
    &:hover,
    &:focus {
      color: ${colors.primary[500]};
    }
  }
  div.list-disc a {
    color:${colors.primary[500]};
  }
  
  /* List */
  ol,
  dl {
    margin: 0;
    padding: 0;
  }
  
  dl dd {
    margin: 0;
  }
  
  /* Embed */
  img {
    border-style: none;
    vertical-align: middle;
    &:not([src$=".svg"]) {
      height: auto;
    }
  }
  iframe {
    border: 0;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  figure {
    img {
      margin: 0;
    }
    margin: 0;
  }
  
  /* Form */
  a,
  area,
  button,
  [role="button"],
  input:not([type="range"]),
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
  }
  input,
  select,
  textarea {
    appearance: none;
    display: block;
  }
  textarea,
  select {
    appearance: none;
  }
  label {
    display: inline-block;
    cursor: pointer;
    abbr {
      display: none;
    }
  }
  textarea {
    resize: vertical;
  }
  input[type="checkbox"],
  input[type="radio"] {
    appearance: auto !important;
  }
  [type="checkbox"],
  [type="radio"] {
    display: inline;
    appearance: none;
    &::-ms-check {
      display: none;
    }
  }
  [type="search"] {
    box-sizing: border-box;
  }
  input:required {
    box-shadow: none;
  }
  select {
    &::-ms-expand {
      display: none;
    }
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 ${colors.dark[600]};
    }
  }
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }
  ::placeholder {
    color: ${colors.neutral[300]};
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  /* Other */
  hr {
    height: 1px;
    border: none;
    background: ${colors.dark[300]};
  }
  
  /* Table */
  table {
    max-width: 100%;
    margin: 0;
    border-collapse: collapse;
  }
  tr,
  td,
  th {
    vertical-align: middle;
  }
  th {
    font-weight: inherit;
    text-align: left;
  }
  
  /* Helper */
  .is-clipped {
    overflow: hidden !important;
  }
  .print-inline,
  .print {
    display: none !important;
  }
  .hide-on-mobile {
    ${mediaQueryMax(breakpoint.smartphone)} {
      display: none !important;
    }
  }
  
  /* Print */
  ${print};
`;
