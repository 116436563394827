import classnames from 'classnames';
import React, { FC, ReactNode, SyntheticEvent } from 'react';

import { StatusProp } from '../../../styles/theme';
import IconLoading from '../../icons/Loading';
import { ButtonSC, ButtonText, IsLoading, LinkEl } from './styles';

export interface ButtonProps extends StatusProp {
  className?: string;
  datas?: any;
  disabled?: boolean;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  large?: boolean;
  loading?: boolean;
  name?: string;
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  size?: 'small' | 'base' | 'big';
  square?: boolean;
  target?: '_blank';
  to?: string;
  type?: string;
  value?: string;
}

/**
 * Button
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.className
 * @param {object} props.datas
 * @param {boolean} props.disabled
 * @param {React.ReactElement} props.iconLeft
 * @param {React.ReactElement} props.iconRight
 * @param {boolean} props.large
 * @param {boolean} props.loading
 * @param {Function} props.onClick
 * @param {string} props.size
 * @param {boolean} props.square
 * @param {string} props.status
 * @param {string} props.to
 * @param {string} props.value
 *
 * @returns {React.ReactElement}
 */
const Button: FC<ButtonProps> = ({
  children,
  className,
  datas,
  disabled = false,
  iconLeft,
  iconRight,
  large = false,
  loading = false,
  onClick,
  size = 'base',
  square = false,
  status,
  to,
  value,
}) => {
  if (to) {
    return (
      <LinkEl
        className={className}
        disabled={disabled}
        large={large}
        size={size}
        square={square}
        status={status}
        to={to}
      >
        {iconLeft}
        {children && (
          <ButtonText
            className={classnames({
              'text-left': iconLeft,
              'text-right': iconRight,
            })}
          >
            {children}
          </ButtonText>
        )}
        {iconRight}
        {loading && (
          <IsLoading>
            <IconLoading />
          </IsLoading>
        )}
      </LinkEl>
    );
  }

  return (
    <ButtonSC
      className={className}
      disabled={disabled}
      large={large}
      onClick={onClick}
      size={size}
      square={square}
      status={status}
      to={to}
      value={value}
      {...datas}
    >
      {iconLeft}
      {children && (
        <ButtonText
          className={classnames({
            'text-left': iconLeft,
            'text-right': iconRight,
          })}
        >
          {children}
        </ButtonText>
      )}
      {iconRight}
      {loading && (
        <IsLoading>
          <IconLoading />
        </IsLoading>
      )}
    </ButtonSC>
  );
};

export default Button;
