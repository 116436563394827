import classnames from 'classnames';
import { Link } from 'gatsby';
import React, { FC } from 'react';

import Translate from '../../../../utils/Translate';
import Button from '../../../base/Button';
import Logo from '../../../base/Logo';
import NavLink from '../../../base/NavLink';
import IconMenu from '../../../icons/Menu';
import { AppHeaderElContainer, AppHeaderElContainerInner } from './styles';

const AppHeader: FC<{ isLogged?: boolean }> = ({ isLogged = false }) => {
  const [showMobileNav, setShowMobileNav] = React.useState(false);

  return (
    <div className="border-b border-light-600 bg-primary-700 z-10 flex-shrink-0">
      <AppHeaderElContainer>
        <AppHeaderElContainerInner className="flex content-center">
          <Link className="flex space-x-2 items-center" to="/">
            <Logo color="white" height={44} />
          </Link>
          <div className="ml-auto md:ml-4 lg:ml-6 flex">
            <Button
              className="ml-2 sm:ml-4 md:hidden"
              iconLeft={<IconMenu />}
              onClick={(): void => setShowMobileNav(!showMobileNav)}
            >
              Menu
            </Button>
          </div>

          {isLogged && (
            <nav
              className={classnames(
                'no-print md:ml-auto md:flex md:space-x-3',
                {
                  hidden: !showMobileNav,
                  'is-open': showMobileNav,
                },
              )}
            >
              <NavLink to="/">
                <Translate i18nKey="links/header/home" />
              </NavLink>
              <NavLink partiallyActive to="/licence/">
                <Translate i18nKey="links/header/licence" />
              </NavLink>
              <NavLink partiallyActive to="/api/">
                <Translate i18nKey="links/header/api" />
              </NavLink>
            </nav>
          )}
        </AppHeaderElContainerInner>
      </AppHeaderElContainer>
    </div>
  );
};

export default AppHeader;
